import { FC, Fragment, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as OrderItemLinkIcon } from '../../svg/icons/orderItemLink.svg'
import { ReactComponent as EditIcon } from '../../svg/ui/edit.svg'
import {
  ApplicationManagerDataType,
  CommentTargetType,
  TaskTargetType,
  useApplicationByIdQuery,
  useCustomerCompanyApplicationsQuery,
  CustomerCompanyApplicationsQuery,
  HistoryTargetType,
  DealStatus
} from '../../graphql/schema'
import { dateFormatter, dateTimeFormatter } from '../../utils/dateFormatter'
import { formatDecimal, formatPercent } from '../../utils/formatNumber'
import NavLink from '../../components/NavLink'
import Comments from '../../components/Comments/Comments'
import Tasks from '../../components/Tasks'
import useNodes from '../../hooks/useNodes'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import { dealStatusDict, sourceCategoryDict } from '../../utils/dictionaries'
import { AppStatus, ApplicationStatusBadge } from '../../components/ApplicationStatus'
import History from '../../components/History'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import Tooltip from '../../components/Tooltip'
import LeasingSubjectCategoriesList from '../../components/LeasingSubjectCategoriesList'
import Modal from '../../components/Modal'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import PhoneNumber from '../../components/PhoneNumber'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card'
import c from 'clsx'
import BkiConsent from './BkiConsent.tsx'
import { CompanyData } from '../../types/dadata.ts'
import { isIP } from '../../utils/contractUtils.ts'
import shortenUrl from 'shorten-url'

const channelMap: Map<string, string> = new Map([
  ['site', 'Сайт'],
  ['backoffice', 'Бэкофис'],
  ['partner_form', 'Партнёрская форма'],
  ['client_form', 'Клиентская форма'],
  ['partner_api', 'Партнёрская API'],
  ['widget', 'Виджет']
])

type Application = NodeType<NonNullable<CustomerCompanyApplicationsQuery['customerCompany']>['applications']>

interface OrderItem {
  title: string
  price: string
  quantity: number
  link: string
}

const urlRegex = /https?:\/\/(?:[\w-]+\.)+[a-zA-Z]{2,6}(?::\d{1,5})?(?:\/[^\s]*)?/gm

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const { data, refetch } = useApplicationByIdQuery({ variables: { id: id?.toString() || '' }, skip: !id })
  const application = data?.application
  const customerCompany = application?.customerCompany
  const companyDadata: CompanyData = useMemo(
    () => (customerCompany?.dadata ? customerCompany.dadata.data : {}),
    [customerCompany?.dadata]
  )
  const IP: boolean = isIP(companyDadata)

  const [editAppId, setEditAppId] = useState<number | undefined>()
  const formOpen = editAppId !== undefined

  const {
    data: customerCompanyApplicationsData,
    loading,
    error
  } = useCustomerCompanyApplicationsQuery({
    variables: { customerCompanyId: customerCompany?._id.toString() as string },
    skip: !customerCompany?._id
  })
  const customerCompanyApplications = useNodes(
    customerCompanyApplicationsData?.customerCompany?.applications?.edges
  ).filter((app) => app._id.toString() !== id)

  const categories = useNodes(data?.application?.leasingSubjectCategories?.edges)

  const orderItems: OrderItem[] = application?.sourceData && JSON.parse(application?.sourceData)?.orderItems
  const manager: ApplicationManagerDataType = application?.sourceData && JSON.parse(application.sourceData)?.manager

  const appTableConfig = useMemo<GridViewConfig<Application>>(
    () => ({
      grid: 'grid-cols-application-s',
      columns: [
        { title: 'ID', value: '_id', numeric: true },
        { title: 'Дата заявки', value: (a) => dateFormatter.format(new Date(a.created)), numeric: true },
        {
          title: 'Статус',
          padding: false,
          value: (a) => (
            <div className='flex items-center px-[5px]'>
              <Tooltip target={<ApplicationStatusBadge status={a.status} />}>
                {a.status === AppStatus.Closed && a.lossReason}
              </Tooltip>
              <div className='ml-5 text-labels-secondary'>
                {a.status === AppStatus.Deal && dealStatusDict[a.deal?.status as DealStatus]}
              </div>
            </div>
          )
        },
        {
          title: 'МЛ',
          padding: false,
          value: (a) => <AvatarTooltip className='mx-auto' userId={a?.user?._id} />
        }
      ],
      rows: {
        link: (a) => `/applications/${a?._id}`
      }
    }),
    []
  )

  const formatCommentText = (text: string): JSX.Element[] => {
    const parts = text.split(urlRegex).reduce<JSX.Element[]>((acc, part, index, arr) => {
      if (index < arr.length - 1) {
        const match = text.match(urlRegex)?.[index] || ''
        acc.push(
          <Fragment key={`${index}-text`}>
            {part}
            <a
              href={match.startsWith('http') ? match : `https://${match}`}
              target='_blank'
              rel='noopener noreferrer'
              className='underline hover:text-red-100'
            >
              {shortenUrl(match, 23)}
            </a>
          </Fragment>
        )
      } else {
        acc.push(<Fragment key={`${index}-text`}>{part}</Fragment>)
      }
      return acc
    }, [])

    return parts
  }

  if (!application) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        {!IP && <BkiConsent customerId={`${customerCompany?._id}`} date={customerCompany?.dataProcessingConsentDate} />}

        <Card>
          <CardHeader>
            <CardTitle>Заявки клиента</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <GridView
              config={appTableConfig}
              data={customerCompanyApplications}
              loading={loading}
              error={error?.message}
            />
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Категории</CardTitle>
            <CardMenu>
              <CardIconButton onClick={() => setEditAppId(data?.application?._id)}>
                <EditIcon />
              </CardIconButton>
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <LeasingSubjectCategoriesList categories={categories} />
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Входные данные</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Сумма</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application.amount ? formatDecimal(application.amount) + ' ₽' : '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Аванс</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application.advanceRate
                        ? `${formatPercent(application.advanceRate)}${application.amount && ' → '}${
                            application.amount && formatDecimal(application.amount * application.advanceRate * 0.01)
                          } ₽`
                        : '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Срок</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application.durationMonths ? application.durationMonths + ' мес' : '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Комментарий</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application.comment ? formatCommentText(application.comment) : '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Дата создания</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {dateTimeFormatter.format(new Date(application.created))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <CardHeader>
            <CardTitle>Источник</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='conte w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Источник</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      <NavLink className='cursor-pointer hover:text-red-100' to={`/sources/${application.source._id}`}>
                        {application.source.name}
                      </NavLink>
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Тип источника</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application?.source?.category ? sourceCategoryDict[application?.source?.category] : '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Канал</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {application.channel ? channelMap.get(application.channel) || '—' : '—'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {manager && (
            <div className='px-5 pb-5'>
              <div className='rounded-md bg-surface-primary shadow-card'>
                <div className='flex p-6'>
                  <p className='pr-10 font-display'>{manager?.fio}</p>
                  <p className='text-grayscale-150'>Менеджер</p>
                </div>
                <div className='border-t-1 border-grayscale-400 p-6'>
                  {manager?.email && (
                    <>
                      <span className='text-grayscale-150'>{manager.email}</span>
                      <span className='mx-5 inline-block rounded-full border-2 border-grayscale-250 align-middle' />
                    </>
                  )}
                  <span className='inline-flex gap-3 text-grayscale-150'>
                    <PhoneNumber number={manager?.phone} additionalNumber={manager?.additionalPhone} />
                  </span>
                </div>
              </div>
            </div>
          )}

          {Array.isArray(orderItems) && orderItems.length > 0 && (
            <>
              <CardHeader>
                <CardTitle>Позиции</CardTitle>
              </CardHeader>
              <div className='px-5 pb-5'>
                <div className='rounded-md bg-surface-primary shadow-card'>
                  <table className='conte w-full'>
                    <tbody>
                      {orderItems.map((orderItem, i) => (
                        <tr className={c('group', !!i && 'border-t-1 border-grayscale-400')} key={i}>
                          <td className='w-90 p-6 first:rounded-l-md last:rounded-r-md'>{orderItem?.title}</td>
                          <td className='p-6'>{orderItem?.quantity} шт.</td>
                          <td className='p-6'>{formatDecimal(Number(orderItem?.price) * 100) + ' ₽'}</td>
                          <td className='w-27 p-6 text-right first:rounded-l-md last:rounded-r-md'>
                            <a href={orderItem?.link} target='_blank' rel='noreferrer' className='hover:text-red-100'>
                              <OrderItemLinkIcon width={18} height={18} />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Tasks targetId={application?._id} targetType={TaskTargetType.Application} />

        <Comments targetId={application._id} targetType={CommentTargetType.Application} />

        <History targetId={application?._id} targetType={HistoryTargetType.Application} />
      </div>
      <Modal open={formOpen} setOpen={() => setEditAppId(undefined)}>
        <div className='absolute top-50 z-10 rounded-xl bg-white-0'>
          <ApplicationForm
            onDone={() => {
              refetch()
              setEditAppId(undefined)
            }}
            id={editAppId}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Info
