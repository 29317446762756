import { FC, useCallback, useMemo, useState } from 'react'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import {
  CustomerAccountKind,
  DealInsuranceKind,
  DealStatus,
  Kind,
  PaymentAttributes,
  PaymentKind,
  PaymentsQuery,
  PaymentState,
  PaymentTargetType,
  useCancelPaymentTransactionsMutation,
  useCreatePaymentMutation,
  useCreatePaymentsMutation,
  useCustomerAccountQuery,
  useDealByIdQuery,
  useDealCalculationsQuery,
  useDealPenaltyAmountLazyQuery,
  useDealRedemptionScheduleQuery,
  useDeletePaymentMutation,
  useDeletePaymentsMutation,
  usePaymentsQuery,
  usePayPaymentMutation,
  useDealPaymentSchedulesQuery,
  UserRoles
} from '../../../graphql/schema.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import Modal from '../../../components/Modal.tsx'
import PaymentForm from './PaymentForm.tsx'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { NodeType } from '../../../types'
import ConfirmationForm from '../../../components/ConfirmationForm.tsx'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import PaymentActions, { PaymentActionType, PaymentActionsIcon } from './PaymentActions.tsx'
import { ReactComponent as AddIcon } from '../../../svg/ui/plus.svg'
import Dropdown from '../../../components/Dropdown'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import TransactionsDateForm from './TransactionsDateForm.tsx'
import PenaltyForm from './PenaltyForm.tsx'
import PaymentSchedulesCompare from './PaymentSchedulesCompare.tsx'
import usePaymentsAccessCheck from './usePaymentsAccessCheck.ts'
import parseDecimal from '../../../utils/parseDecimal.ts'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'
import { Card, CardAccentButton, CardHeader, CardMenu, CardTitle } from '../../../components/Card.tsx'
import ScheduleForm from '../Schedules/ScheduleForm.tsx'
import { ReactComponent as CompareIcon } from '../../../svg/ui/compare.svg'

interface PaymentsProps {
  dealId: number
}

export type Payment = NodeType<PaymentsQuery['payments']> & { penaltyPayments?: Payment[] }

const Payments: FC<PaymentsProps> = ({ dealId }) => {
  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.roles.includes(UserRoles.RoleAdmin)
  const isQCMS = currentUser?.roles.includes(UserRoles.RoleQualityControlSupportManager)
  const [scheduleToCompareId, setScheduleToCompareId] = useState<string | undefined>()

  const { data: dealData } = useDealByIdQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const deal = dealData?.deal

  const {
    canPaymentsManage,
    canServicePaymentsCreate,
    canDebtTransferServicePaymentsCreate,
    canPenaltyPaymentsCreate,
    canPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate,
    canPayPayment
  } = usePaymentsAccessCheck(deal?.status)

  const { data: paymentScheduleData, refetch: refetchPaymentSchedules } = useDealPaymentSchedulesQuery({
    variables: { dealId: dealId.toString() }
  })

  const paymentSchedules = paymentScheduleData?.paymentSchedules || []
  const actualSchedule = paymentSchedules[0]

  const { data: mainCustomerAccountData, refetch: refetchMainCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Main },
    skip: !deal?.customerCompany?._id
  })
  const mainCustomerAccount = mainCustomerAccountData?.customerAccount

  const { data: penaltyCustomerAccountData, refetch: refetchPenaltyCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Penalty },
    skip: !deal?.customerCompany?._id
  })
  const penaltyCustomerAccount = penaltyCustomerAccountData?.customerAccount

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    },
    skip:
      !deal ||
      deal.amount === null ||
      deal.advanceRate === null ||
      deal.durationMonths === null ||
      deal.comissionRate === null ||
      deal.advancePaymentDate === null
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const {
    data: basePaymentsData,
    refetch: refetchBasePayments,
    loading: basePaymentsLoading,
    error: basePaymentsError
  } = usePaymentsQuery({
    variables: {
      kinds: [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Redemption,
        PaymentKind.Insurance,
        PaymentKind.Service,
        PaymentKind.Compensation,
        PaymentKind.DebtTransferService,
        PaymentKind.Buyout,
        PaymentKind.Body
      ],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const basePayments = useNodes(basePaymentsData?.payments.edges)
  const flatBasePayments = useMemo(
    () => basePayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [basePayments]
  )

  const kinds: PaymentKind[] = useMemo(() => {
    if (canPaymentsManage) {
      const baseKinds = [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Insurance,
        PaymentKind.Redemption,
        PaymentKind.Buyout,
        PaymentKind.Body
      ].filter((kind) => !basePayments.find((payment) => payment.kind === kind))

      if (!baseKinds.length) {
        return [PaymentKind.Service, PaymentKind.Compensation, PaymentKind.DebtTransferService]
      }
      return [...baseKinds, PaymentKind.Service, PaymentKind.Compensation, PaymentKind.DebtTransferService]
    }
    if (canServicePaymentsCreate && canDebtTransferServicePaymentsCreate) {
      return [PaymentKind.Service, PaymentKind.DebtTransferService]
    }
    if (canServicePaymentsCreate) {
      return [PaymentKind.Service]
    }
    if (canDebtTransferServicePaymentsCreate) {
      return [PaymentKind.DebtTransferService]
    }
    return []
  }, [canPaymentsManage, canServicePaymentsCreate, canDebtTransferServicePaymentsCreate, basePayments])

  const {
    data: leasingPaymentsData,
    refetch: refetchLeasingPayments,
    loading: leasingPaymentsLoading,
    error: leasingPaymentsError
  } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges)
  const flatLeasingPayments = useMemo(
    () => leasingPayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [leasingPayments]
  )

  const { data: dealRedemptionScheduleData } = useDealRedemptionScheduleQuery({
    variables: { dealId: dealId.toString() }
  })
  const dealRedemptionSchedules = dealRedemptionScheduleData?.dealRedemptionSchedule

  // для создания графика
  const [createPayments] = useCreatePaymentsMutation()

  // для создания пеней автоматически, без открытия формы
  const [createPayment] = useCreatePaymentMutation()

  const [penaltyForDeal] = useDealPenaltyAmountLazyQuery()

  const [deletePayment] = useDeletePaymentMutation()

  const [deletePayments] = useDeletePaymentsMutation()

  const [payPaymentMutation] = usePayPaymentMutation()

  const [deletionFormOpenByPaymentId, setDeletionFormOpenByPaymentId] = useState<string>()
  const [disableBasePayments, setDisableBasePayments] = useState(false)
  const [basePaymentFormOpen, setBasePaymentFormOpen] = useState(false)
  const [leasingPaymentFormOpen, setLeasingPaymentFormOpen] = useState(false)
  const [penaltyFormOpenByPaymentId, setPenaltyFormOpenByPaymentId] = useState<string>()
  const [leasingPaymentsDeletionFormOpen, setLeasingPaymentsDeletionFormOpen] = useState(false)
  const [editTransactionsFormOpenByPaymentId, setEditTransactionsFormOpenByPaymentId] = useState<string>()
  const [penaltyCalculationLoading, setPenaltyCalculationLoading] = useState(false)
  const [scheduleFormOpen, setScheduleFormOpen] = useState(false)

  const readyPaymentsCreate = Boolean(
    deal?.contractNumber &&
      deal?.contractDate &&
      deal?.advancePaymentDate &&
      deal?.supplyingStatus &&
      deal?.amount >= 0 &&
      deal?.durationMonths >= 0 &&
      deal?.advanceRate >= 0 &&
      deal?.interestRate >= 0 &&
      deal?.comissionRate >= 0 &&
      ((deal?.insuranceKind === DealInsuranceKind.WithInsurance && deal.insuranceAmount >= 0) ||
        deal?.insuranceKind === DealInsuranceKind.WithoutInsurance) &&
      deal?.vatRate >= 0 &&
      deal?.leasingSubjectCategories?.edges?.length &&
      deal?.status &&
      deal?.kind &&
      deal?.user
  )

  const statusAllowsLeasingPaymentsCreation =
    deal?.status === DealStatus.Signing || // на этом статусе можно создавать всем
    deal?.status === DealStatus.Activation || // на этом тоько админам
    deal?.status === DealStatus.PaymentReception // на этом тоько админам

  const userAllowedToCreateLeasingPayments =
    (deal?.status === DealStatus.Signing && (isAdmin || isQCMS || !leasingPayments.length)) || // на этом статусе можно создавать всем, если нет платежей
    (deal?.status === DealStatus.Activation && (isAdmin || isQCMS)) || // на этом тоько админам и качеству
    (deal?.status === DealStatus.PaymentReception && (isAdmin || isQCMS)) // на этом тоько админам и качеству

  async function removeLeasingPayments() {
    if (!leasingPayments.length) {
      return
    }
    await deletePayments({
      variables: {
        input: {
          ids: leasingPayments.map((p) => p.id)
        }
      }
    })

    await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
  }

  const [cancelPaymentTransactions] = useCancelPaymentTransactionsMutation({
    onCompleted: () => {
      refetchMainCustomerAccount()
      refetchPenaltyCustomerAccount()
    }
  })

  const cancelPaymentTransactionsCallback = useCallback(
    async (paymentId: string) => {
      await cancelPaymentTransactions({
        variables: {
          input: {
            paymentId
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cancelPaymentTransactions: {
            __typename: 'CancelPaymentTransactionsPayload',
            payment: {
              __typename: 'Payment',
              state: PaymentState.Pending,
              id: paymentId
            }
          }
        }
      })
    },
    [cancelPaymentTransactions]
  )

  const payPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: mainCustomerAccount?.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments(), refetchMainCustomerAccount()])
    },
    [
      mainCustomerAccount?.id,
      payPaymentMutation,
      refetchBasePayments,
      refetchMainCustomerAccount,
      refetchLeasingPayments
    ]
  )

  const payPenaltyPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !penaltyCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: penaltyCustomerAccount.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchLeasingPayments(), refetchPenaltyCustomerAccount()])
    },
    [penaltyCustomerAccount?.id, payPaymentMutation, refetchPenaltyCustomerAccount, refetchLeasingPayments]
  )

  const recountPenalty = useCallback(
    async (payment: { id: string; targetId: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      try {
        setPenaltyCalculationLoading(true)

        const { data: newPenaltyAmount } = await penaltyForDeal({ variables: { paymentId: payment.targetId } })

        await deletePayment({
          variables: {
            input: {
              id: payment.id
            }
          }
        })

        await createPayment({
          variables: {
            input: {
              amount: parseDecimal((newPenaltyAmount?.penaltyAmountForPayment.amount || '').toString()),
              date: new Date(),
              kind: PaymentKind.Penalty,
              targetId: payment.targetId,
              targetType: PaymentTargetType.Payment
            }
          }
        })

        await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
      } catch (e) {
        console.error(e)
      } finally {
        setPenaltyCalculationLoading(false)
      }
    },
    [mainCustomerAccount?.id, penaltyForDeal, deletePayment, createPayment, refetchBasePayments, refetchLeasingPayments]
  )

  const canPayBasePaymentId = basePayments.find((payment) => payment.state !== PaymentState.Paid)?.id
  const canPayLeasingPaymentId = leasingPayments.find(
    (payment) => payment.kind === PaymentKind.Leasing && payment.state !== PaymentState.Paid
  )?.id

  const basePaymentsConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment-base',
      columns: [
        { title: 'Тип', value: (p) => paymentKindDict[p.kind] },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)), numeric: true },
        {
          title: 'Итого, ₽',
          value: (p) => (
            <div className='flex w-full justify-between gap-x-16'>
              {formatDecimal(p.amount * 100)}
              <PaymentActionsIcon payment={p} />
            </div>
          ),
          numeric: true
        },
        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage || canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p.penaltyPayments.length)}
                      canPaymentsManage={canPaymentsManage}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment && p.id === canPayBasePaymentId}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p.id)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
    }),
    [
      canPaymentsManage,
      canPaymentsEditTransactionsDate,
      canPayPayment,
      canPayBasePaymentId,
      cancelPaymentTransactionsCallback,
      payPayment
    ]
  )

  const leasingPaymentsConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment',
      columns: [
        {
          title: '№',
          value: (p) => {
            if (p.kind === PaymentKind.Penalty) {
              return ' '
            }
            return leasingPayments.findIndex((payment) => payment.id === p.id) + 1
          },
          numeric: true
        },
        {
          title: 'Пени',
          value: (p) => {
            if (p.kind === PaymentKind.Penalty) {
              return 'Пени'
            }
            return ' '
          }
        },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)), numeric: true },
        {
          title: 'Итого, ₽',
          value: (p) => <div className='flex w-full justify-between gap-x-16'>{formatDecimal(p.amount * 100)}</div>,
          numeric: true
        },
        {
          title: 'Выкупная стоимость, ₽',
          value: (p) => {
            const paymentId = parseInt(p.id)
            const paymentRedemption = dealRedemptionSchedules?.find((redemption) => redemption.paymentId === paymentId)

            return (
              <div className='flex w-full justify-between gap-x-16'>
                {isAdmin && paymentRedemption ? formatDecimal(paymentRedemption.redemption * 100) : '-'}
                <PaymentActionsIcon payment={p} />
              </div>
            )
          },
          numeric: true
        },

        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage ||
                  canPenaltyPaymentsCreate ||
                  canPenaltyPaymentsDelete ||
                  canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p?.penaltyPayments?.length)}
                      canPaymentsManage={canPaymentsManage}
                      canPenaltyCreate={canPenaltyPaymentsCreate}
                      canPenaltyDelete={canPenaltyPaymentsDelete}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment && p.id === canPayLeasingPaymentId}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p.id)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPenaltyPayment:
                            await payPenaltyPayment(p)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                          case PaymentActionType.RecountPenalty:
                            await recountPenalty(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
    }),
    [
      leasingPayments,
      dealRedemptionSchedules,
      isAdmin,
      canPaymentsManage,
      canPenaltyPaymentsCreate,
      canPenaltyPaymentsDelete,
      canPaymentsEditTransactionsDate,
      canPayPayment,
      canPayLeasingPaymentId,
      cancelPaymentTransactionsCallback,
      payPenaltyPayment,
      payPayment,
      recountPenalty
    ]
  )

  async function createBasePayments() {
    if (!deal || !dealCalculations || !deal.advancePaymentDate) return

    const newPayments: Array<PaymentAttributes> = []

    newPayments.push({
      amount: dealCalculations.advanceAmount,
      date: deal.advancePaymentDate,
      kind: PaymentKind.Advance,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    newPayments.push({
      amount: dealCalculations.comissionAmount,
      date: deal.advancePaymentDate,
      kind: PaymentKind.Commission,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    if (deal?.insuranceKind === DealInsuranceKind.WithInsurance) {
      newPayments.push({
        amount: deal.insuranceAmount / 100,
        date: deal.advancePaymentDate,
        kind: PaymentKind.Insurance,
        targetId: dealId.toString(),
        targetType: PaymentTargetType.Deal
      })
    }

    const d = new Date(deal.advancePaymentDate)
    d.setDate(d.getDate() - 3)
    d.setMonth(d.getMonth() + deal.durationMonths)

    newPayments.push({
      amount: 1000,
      date: new Date(d),
      kind: PaymentKind.Redemption,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    setDisableBasePayments(true)
    try {
      await createPayments({ variables: { input: { payments: newPayments } } })
      await refetchBasePayments()
    } finally {
      setDisableBasePayments(false)
    }
  }

  console.log(paymentSchedules)

  if (!deal) return null

  return (
    <div className='flex flex-col gap-6'>
      {/* Если таблица платежей в процессе загрузки, или она загружена и имеет хотя бы один платеж, выводится таблица */}
      <Card>
        <CardHeader>
          <CardTitle>Платежи</CardTitle>

          {!basePaymentsLoading && (
            <CardMenu>
              {basePayments.length === 0 && deal?.status !== DealStatus.Signing ? (
                <div className='text-labels-tertiary'>На данном этапе нельзя создать платежи</div>
              ) : !readyPaymentsCreate ? (
                <div className='text-labels-tertiary'>Недостаточно данных для создания платежей</div>
              ) : (
                (canPaymentsManage || canServicePaymentsCreate) &&
                (basePayments.length === 0 ? (
                  <CardAccentButton disabled={disableBasePayments} onClick={createBasePayments}>
                    <AddIcon />
                    Создать аванс, комиссию, страховку и выкупной
                  </CardAccentButton>
                ) : (
                  <CardAccentButton disabled={disableBasePayments} onClick={() => setBasePaymentFormOpen(true)}>
                    <AddIcon />
                    Добавить платеж
                  </CardAccentButton>
                ))
              )}
            </CardMenu>
          )}
        </CardHeader>
        {flatBasePayments?.length > 0 && (
          <div className='px-5 pb-5'>
            <GridView
              data={flatBasePayments}
              config={basePaymentsConfig}
              loading={basePaymentsLoading}
              error={basePaymentsError?.message}
            />
          </div>
        )}
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>
            График платежей
            {!!actualSchedule &&
              ` № ${paymentSchedules.length} от ${actualSchedule && dateFormatter.format(new Date(actualSchedule.createdAt))}`}
          </CardTitle>

          {userAllowedToCreateLeasingPayments && (
            <CardMenu>
              {!statusAllowsLeasingPaymentsCreation ? (
                <div className='text-labels-tertiary'>На данном этапе нельзя создать график</div>
              ) : !readyPaymentsCreate ? (
                <div className='text-labels-tertiary'>Недостаточно данных для создания графика</div>
              ) : (
                <CardAccentButton onClick={() => setScheduleFormOpen(true)}>
                  <AddIcon />
                  Создать {!!leasingPayments?.length && 'новый'} график
                </CardAccentButton>
              )}
            </CardMenu>
          )}
        </CardHeader>
        <div className='pb-5 empty:pb-0'>
          {leasingPayments?.length > 0 && (
            <div className='px-5'>
              <GridView
                data={flatLeasingPayments}
                config={leasingPaymentsConfig}
                loading={leasingPaymentsLoading || penaltyCalculationLoading}
                error={leasingPaymentsError?.message}
              />
            </div>
          )}
          {paymentSchedules.length > 1 && (
            <div>
              <CardHeader>
                <CardTitle>Архивные графики</CardTitle>
              </CardHeader>
              <div className='px-5'>
                {paymentSchedules.map((p, i) => {
                  if (i === 0) return null
                  return (
                    <button
                      key={p.id}
                      onClick={() => setScheduleToCompareId(p.id)}
                      className='flex w-full items-center justify-between bg-surface-primary px-6 py-4 text-left text-labels-secondary shadow-card first:rounded-t-md last:rounded-b-md hover:text-labels-primary hover:shadow-card-hover active:bg-surface-secondary disabled:cursor-not-allowed disabled:text-labels-tertiary'
                    >
                      График № {paymentSchedules.length - i} от {dateFormatter.format(new Date(p.createdAt))}
                      <CompareIcon />
                    </button>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </Card>

      <Modal open={basePaymentFormOpen} setOpen={setBasePaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentForm
            dealId={dealId}
            onDone={() => {
              refetchBasePayments()
              setBasePaymentFormOpen(false)
            }}
            kinds={kinds}
          />
        </div>
      </Modal>
      <Modal open={leasingPaymentFormOpen} setOpen={setLeasingPaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentForm
            dealId={dealId}
            onDone={() => {
              refetchLeasingPayments()
              setLeasingPaymentFormOpen(false)
            }}
          />
        </div>
      </Modal>
      <SuspenseModal open={!!penaltyFormOpenByPaymentId} setOpen={() => setPenaltyFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PenaltyForm
            paymentId={penaltyFormOpenByPaymentId as string}
            onDone={() => {
              refetchLeasingPayments()
              setPenaltyFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={!!deletionFormOpenByPaymentId} setOpen={() => setDeletionFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление платежа'
            onDone={async () => {
              await deletePayment({ variables: { input: { id: deletionFormOpenByPaymentId as string } } })
              await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
              setDeletionFormOpenByPaymentId(undefined)
            }}
            onDismiss={() => setDeletionFormOpenByPaymentId(undefined)}
          >
            Вы действительно хотите удалить платеж?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal open={leasingPaymentsDeletionFormOpen} setOpen={setLeasingPaymentsDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление графика'
            onDone={async () => {
              setLeasingPaymentsDeletionFormOpen(false)
              await removeLeasingPayments()
            }}
            onDismiss={() => setLeasingPaymentsDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить график?
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal
        open={!!editTransactionsFormOpenByPaymentId}
        setOpen={() => setEditTransactionsFormOpenByPaymentId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <TransactionsDateForm
            paymentId={editTransactionsFormOpenByPaymentId as string}
            onDone={() => {
              refetchBasePayments()
              refetchLeasingPayments()
              setEditTransactionsFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={scheduleFormOpen} setOpen={setScheduleFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ScheduleForm
            dealId={deal._id}
            onDone={() => {
              setScheduleFormOpen(false)
              refetchLeasingPayments()
              refetchPaymentSchedules()
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!scheduleToCompareId} setOpen={() => setScheduleToCompareId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentSchedulesCompare
            scheduleAId={scheduleToCompareId as string}
            scheduleAIndex={paymentSchedules.length - paymentSchedules.findIndex((p) => p.id === scheduleToCompareId)}
            scheduleBId={actualSchedule?.id}
            scheduleBIndex={paymentSchedules.length}
            actualScheduleId={actualSchedule?.id}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Payments
