import { FC, useMemo, useState } from 'react'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import Input from '../../../components/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import downloadFile from '../../../utils/downloadFileServerless'
import { CompanyData } from '../../../types/dadata'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  getFormattedGuaranteeNumber,
  isIP
} from '../../../utils/contractUtils'
import {
  useBoVerificationsLazyQuery,
  useCreateCollectionNoticePdfMutation,
  useCreateCollectionPretensionPdfMutation,
  useCreateCollectionRequirementPdfMutation,
  useDealForCollectionSuspenseQuery,
  useFeraBankAccountsSuspenseQuery
} from '../../../graphql/schema'
import SubmitButton from '../../../components/SubmitButton'
import useNodes from '../../../hooks/useNodes'
import { Company } from '../models'
import MaskedInput from '../../../components/MaskedInput'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup.tsx'
import ClaimForm from './ClaimForm.tsx'
import useClaimSchedule from './hooks/useClaimSchedule.tsx'
import { suspend } from 'suspend-react'
import RegAddressFromVerifications from './RegAddressFromVerifications.tsx'

interface CollectionFormProps {
  dealId: number
}

type Inputs = {
  company: Company
  pretensionN: string
  pretensionDate: string
  contractN: string
  contractDate: string
  guarantors: Guarantor[]
  claimSchedule: Claim[]
  feraBankAccount?: {
    bankKey: string
    bic: string
    corrNumber: string
    name: string
    number: string
  }
}

export type Claim = {
  number: number | string
  amount?: number
  date?: string
  overdueDays?: number
  overdueEnd?: string
  overdueFrom?: string
  paidAmount?: number
  paidPenaltyAmount?: number
  penaltyAmount?: number
}

type Guarantor = {
  _id: number
  contractNumber: string
  contractNumberCount: number
  name: string
  guaranteeContractN: string
  guaranteeContractDate: string
  person?: {
    _id: number
    fio: string
    address: string
  }
  company?: {
    isIP: boolean
    inn: string
    fullWithOpf: string
    shortWithOpf: string
    dadata: CompanyData
    address: string
  }
}

const CollectionForm: FC<CollectionFormProps> = ({ dealId }) => {
  const [kind, setKind] = useState<string>('pretension')
  const { data } = useDealForCollectionSuspenseQuery({ variables: { id: `${dealId}` } })
  const deal = data?.deal
  const guarantorNodes = useNodes(deal?.guarantors?.edges)
  const contactsList = useNodes(deal?.application.contacts?.edges)

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const { getClaimSchedule, saveClaimSchedule, saveClaimScheduleLoading } = useClaimSchedule(dealId)
  const [boVerificationsQuery] = useBoVerificationsLazyQuery()

  const [createNotice] = useCreateCollectionNoticePdfMutation()
  const [createPretension] = useCreateCollectionPretensionPdfMutation()
  const [createRequirement] = useCreateCollectionRequirementPdfMutation()

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.dadata ? deal?.customerCompany.dadata.data : {}),
    [deal?.customerCompany]
  )

  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const feraBankAccount = feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId)

  const guarantorsRaw = suspend(async () => {
    const boVerificationsData = await Promise.all(
      guarantorNodes
        .filter((g) => !!g?.contact?._id)
        .map((g) =>
          boVerificationsQuery({
            variables: { contactId: g?.contact?._id as number, states: ['accepted'] }
          })
        )
    )
    const boVerifications = boVerificationsData.map((d) => d.data?.boVerifications[0])

    return guarantorNodes.map((g) => ({
      _id: g._id,
      name: g.contact ? g.contact?.fio : g.guarantorCompany?.shortWithOpf,
      contractNumberCount: g.contractNumber,
      guaranteeContractN: getFormattedGuaranteeNumber(deal?.contractNumber || '', g.contractNumber),
      guaranteeContractDate: getDateFormattedString(new Date(deal?.contractDate as string)),
      person: g.contact
        ? {
            _id: g.contact?._id,
            fio: g.contact?.fio,
            address: boVerifications.find((v) => v?.contactId === g.contact?._id)?.personInfo?.regAddress
          }
        : undefined,
      company: !g.contact
        ? {
            isIP: isIP(g.guarantorCompany?.dadata?.data),
            inn: g.guarantorCompany?.inn,
            fullWithOpf: g.guarantorCompany?.fullWithOpf,
            shortWithOpf: g.guarantorCompany?.shortWithOpf,
            dadata: g.guarantorCompany?.dadata?.data,
            address: g.guarantorCompany?.dadata?.data?.address?.value || ''
          }
        : undefined
    }))
  }, [deal?._id, guarantorNodes.map((g) => g._id).toString()])

  const form = useForm<Inputs>({
    defaultValues: {
      company: {
        formShort: deal?.customerCompany?.shortWithOpf
          ? getCompanyForm(deal?.customerCompany?.shortWithOpf, companyName)
          : '',
        form: deal?.customerCompany?.fullWithOpf
          ? getCompanyForm(deal?.customerCompany?.fullWithOpf, companyName, true)
          : '',
        name: companyName,
        requisites: {
          kpp: !IP ? companyDadata.kpp : undefined,
          inn: deal?.customerCompany.inn,
          legalAddress: companyDadata.address?.value,
          ogrn: companyDadata.ogrn || ''
        }
      },
      pretensionDate: new Date().toISOString().split('T').shift(),
      contractN: getFormattedContractNumber(deal?.contractNumber),
      contractDate: getDateFormattedString(new Date(deal?.contractDate as string)),
      guarantors: guarantorsRaw,
      feraBankAccount: feraBankAccount
        ? {
            bankKey: feraBankAccount?.bankKey,
            bic: feraBankAccount?.bic,
            corrNumber: feraBankAccount?.corrNumber,
            name: feraBankAccount?.name,
            number: feraBankAccount?.number
          }
        : undefined
    }
  })
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = form

  const guarantors = watch('guarantors')

  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (loading) {
      return
    }
    setLoading(true)

    const company = {
      ...data.company,
      requisites: {
        ...data.company.requisites,
        // FIXME: надо что-то сделать с дефолтным адресом компании либо тут либо в генераторе
        legalAddress: data.company.requisites.legalAddress || ''
      }
    }

    try {
      const claimSchedule = (await getClaimSchedule(data.pretensionDate))?.map((c) => ({
        amount: c.amount,
        date: c.date ? getDateFormattedString(new Date(c.date)) : '',
        number: c.number,
        overdueDays: c.overdueDays || undefined,
        overdueEnd: c.overdueEnd ? getDateFormattedString(new Date(c.overdueEnd)) : undefined,
        overdueFrom: c.overdueFrom ? getDateFormattedString(new Date(c.overdueFrom)) : undefined,
        paidAmount: c.paidAmount || undefined,
        paidPenaltyAmount: c.paidPenaltyAmount || undefined,
        penaltyAmount: c.penaltyAmount || undefined,
        transactionPaidAt: c.transactionPaidAt ? getDateFormattedString(new Date(c.transactionPaidAt)) : undefined
      }))

      if (!claimSchedule) throw new Error('')

      const createPretensionResult = await createPretension({
        variables: {
          input: {
            company,
            claimSchedule,
            contractN: data.contractN,
            contractDate: data.contractDate,
            pretensionN: data.pretensionN,
            pretensionDate: getDateFormattedString(new Date(data.pretensionDate)),
            guarantors: data.guarantors.map((g) => ({
              guaranteeContractN: g.guaranteeContractN,
              guaranteeContractDate: g.guaranteeContractDate
            })),
            feraBankAccount: data.feraBankAccount
          }
        }
      })

      const pretensionUrl = createPretensionResult?.data?.createCollectionPretension?.url
      if (!pretensionUrl) throw new Error('Не удалось получить ссылку на файл претензии')

      await downloadFile(
        pretensionUrl,
        `${getDateFormattedInDocumentName(new Date())} Претензия №${data.pretensionN} от ${getDateFormattedString(
          new Date(data.pretensionDate)
        )}.pdf`
      )

      await Promise.all(
        data.guarantors.map(async (g) => {
          const guarantorCompanyIP: boolean = isIP(g.company?.dadata)
          const guarantorCompanyName = guarantorCompanyIP
            ? capitalizeFirstInWords(g.company?.dadata?.name?.full || '')
            : capitalizeFirst(g.company?.dadata?.name?.full || '')
          const baseData = {
            contractN: data.contractN,
            contractDate: data.contractDate,
            company,
            claimSchedule: data.claimSchedule,
            feraBankAccount: data.feraBankAccount || undefined,
            guarantor: {
              guaranteeContractN: g.guaranteeContractN,
              guaranteeContractDate: g.guaranteeContractDate,
              person: g.person
                ? {
                    fio: g.person?.fio,
                    address: g.person?.address || ''
                  }
                : undefined,
              company: !g.person
                ? {
                    isIP: g.company?.isIP as boolean,
                    formShort: g.company?.shortWithOpf
                      ? getCompanyForm(g.company.shortWithOpf, guarantorCompanyName)
                      : '',
                    form: g.company?.fullWithOpf
                      ? getCompanyForm(g.company.fullWithOpf, guarantorCompanyName, true)
                      : '',
                    name: guarantorCompanyName,
                    inn: g.company?.inn as string,
                    address: g.company?.address || ''
                  }
                : undefined
            }
          }

          return [
            createNotice({
              variables: {
                input: {
                  ...baseData,
                  claimSchedule,
                  noticeN: `УВД${g.contractNumber}`,
                  noticeDate: getDateFormattedString(new Date(data.pretensionDate))
                }
              }
            }).then((result) => {
              const url = result?.data?.createCollectionNotice?.url
              if (!url) throw new Error('Не удалось получить ссылку на уведомление')
              downloadFile(
                url,
                `${getDateFormattedInDocumentName(new Date())} Уведомление №УВД${
                  g.contractNumber
                } от ${getDateFormattedString(new Date(data.pretensionDate))}.pdf`
              )
            }),
            createRequirement({
              variables: {
                input: {
                  ...baseData,
                  claimSchedule,
                  requirementN: `ТРБ${g.contractNumber}`,
                  requirementDate: getDateFormattedString(new Date(data.pretensionDate))
                }
              }
            }).then((result) => {
              const url = result?.data?.createCollectionRequirement?.url
              if (!url) throw new Error('Не удалось получить ссылку на требование')
              downloadFile(
                url,
                `${getDateFormattedInDocumentName(new Date())} Требование №ТРБ${
                  g.contractNumber
                } от ${getDateFormattedString(new Date(data.pretensionDate))}.pdf`
              )
            })
          ]
        })
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!deal) return null

  return (
    <section className='w-[647px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Документы для работы с проблемными клиентами</h1>
      <RadioButtonsGroup
        className='mb-16'
        onChange={(value) => setKind(value)}
        options={[
          ['Претензия', 'pretension'],
          ['Предварительное исковое', 'claim']
        ]}
        checkedValue={kind}
        label='Тип документа'
      />
      {kind === 'pretension' ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-8 grid grid-cols-2 gap-20'>
            <MaskedInput
              label='Номер претензии'
              type='text'
              placeholder='ПТЗ000000-00-00'
              mask='ПТЗ000000-00-00'
              {...register('pretensionN', {
                required: true,
                validate: (value) => {
                  if (value?.length !== 15) {
                    return 'Введите номер претензии полностью (ПТЗ000000-00-00)'
                  }
                  return true
                }
              })}
              error={errors.pretensionN}
            />
            <Input label='Дата претензии' type='date' {...register('pretensionDate', { required: true })} />
          </div>
          <div className='mb-8 grid grid-cols-2 gap-20'>
            <div>
              <Input
                label='Юридический адрес клиента'
                type='text'
                autoComplete='off'
                placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                {...register('company.requisites.legalAddress', { required: true })}
              />
              {isIP(companyDadata) && (
                <RegAddressFromVerifications
                  inn={deal?.customerCompany.inn}
                  contactsList={contactsList}
                  onSetAddress={(address) => setValue('company.requisites.legalAddress', address)}
                />
              )}
            </div>
          </div>
          {guarantors
            ?.map((g, index) => (
              <div key={g._id} className='mb-8 grid grid-cols-2 gap-x-20 gap-y-8'>
                <Input
                  readOnly
                  label={`Поручитель ${g.contractNumberCount}`}
                  placeholder='Петров Петр Петрович или ООО «ФЕРА»'
                  {...register(`guarantors.${index}.name`, { required: true })}
                  error={errors.guarantors?.[index]?.name}
                />
                <MaskedInput
                  label='Номер уведомления/требования'
                  type='text'
                  placeholder='220201-01-01'
                  mask='000000-00-00'
                  {...register(`guarantors.${index}.contractNumber`, { required: true })}
                  error={errors.guarantors?.[index]?.contractNumber}
                />
                <div>
                  <Input
                    label={`Юридический адрес поручителя ${g.contractNumberCount}`}
                    type='text'
                    autoComplete='off'
                    placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                    {...register(`guarantors.${index}.${g.person ? 'person' : 'company'}.address`, { required: true })}
                  />
                  {g.company?.isIP && (
                    <RegAddressFromVerifications
                      inn={g.company?.inn}
                      contactsList={contactsList}
                      onSetAddress={(address) => setValue(`guarantors.${index}.company.address`, address)}
                    />
                  )}
                </div>
              </div>
            ))
            .reverse()}
          <SubmitButton className='mt-12' loading={loading}>
            <FileIcon className='mr-5' />
            Сгенерировать
          </SubmitButton>
        </form>
      ) : (
        <ClaimForm dealId={dealId} />
      )}

      <button
        type='button'
        className='mt-10 flex w-full justify-center text-red-150 hover:text-red-100/70 disabled:cursor-not-allowed disabled:text-grayscale-250'
        disabled={saveClaimScheduleLoading}
        onClick={() => saveClaimSchedule(deal?.contractNumber || '', getValues('pretensionDate'))}
      >
        Скачать таблицу с платежами
      </button>
    </section>
  )
}

export default CollectionForm
