import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { CommentTargetType, CustomerAccountKind, useDealForCollectionProcessQuery } from '../../../graphql/schema'
import CustomerCompanyBalance from '../../../components/CustomerCompanyBalance'
import CollectionPayments from './CollectionPayments'
import { Card, CardHeader, CardMenu, CardTitle } from '../../../components/Card.tsx'
import Collector from './Collector.tsx'
import Comments from '../../../components/Comments/Comments.tsx'

const Collection: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useDealForCollectionProcessQuery({ variables: { id: `${id}` }, skip: !id })
  const deal = data?.deal

  if (!deal) return null

  return (
    <div className='grid'>
      <div className='mb-6 grid grid-cols-3 gap-x-5'>
        <CustomerCompanyBalance
          customerId={deal?.customerCompany?._id}
          title='Основной баланс'
          kind={CustomerAccountKind.Main}
        />
        <CustomerCompanyBalance
          customerId={deal?.customerCompany?._id}
          title='Баланс пеней'
          kind={CustomerAccountKind.Penalty}
        />
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Платежи</CardTitle>
          <CardMenu>
            <Collector dealId={deal._id} collectorId={deal?.collector?._id} />
          </CardMenu>
        </CardHeader>

        <div className='p-5'>
          <CollectionPayments dealId={deal?._id} />
        </div>
      </Card>

      <div className='grid grid-cols-2'>
        <div className='col-start-2 mt-5 grid'>
          <Comments targetId={deal._id} targetType={CommentTargetType.Deal} />
        </div>
      </div>
    </div>
  )
}

export default Collection
