import { FC, Fragment, useState } from 'react'
import { Comment, useUserByIdQuery } from '../../graphql/schema'
import useInterval from '../../hooks/useInterval'
import Avatar from '../Avatar'
import shortenUrl from 'shorten-url'

interface CommentsItemProps {
  comment: Comment
}

const calculateSentCommentTime = (date: Date) => {
  const hourAgo = new Date()
  hourAgo.setHours(hourAgo.getHours() - 1)

  return new Date(date) > hourAgo
    ? new Date(date).getHours() === new Date().getHours() &&
      Math.floor((new Date().getTime() - new Date(date).getTime()) / 60000) <= 0
      ? 'сейчас'
      : `${Math.floor((new Date().getTime() - new Date(date).getTime()) / 60000)} мин назад`
    : Intl.DateTimeFormat('ru-RU', { timeStyle: 'short' }).format(new Date(date))
}

const CommentsItem: FC<CommentsItemProps> = ({ comment }) => {
  const { data: userData } = useUserByIdQuery({
    variables: { id: comment.userId.toString() || '' },
    skip: !comment.userId
  })
  const user = userData?.user

  const [sentCommentTime, setSentCommentTime] = useState<string>(calculateSentCommentTime(comment.createdDate))

  useInterval(() => setSentCommentTime(calculateSentCommentTime(comment.createdDate)), 60000)

  const urlRegex = /https?:\/\/(?:[\w-]+\.)+[a-zA-Z]{2,6}(?::\d{1,5})?(?:\/[^\s]*)?/gm

  const formatCommentText = (text: string): JSX.Element[] => {
    const parts = text.split(urlRegex).reduce<JSX.Element[]>((acc, part, index, arr) => {
      if (index < arr.length - 1) {
        const match = text.match(urlRegex)?.[index] || ''
        acc.push(
          <Fragment key={`${index}-text`}>
            {part}
            <a
              href={match.startsWith('http') ? match : `https://${match}`}
              target='_blank'
              rel='noopener noreferrer'
              className='underline hover:text-red-100'
            >
              {shortenUrl(match, 23)}
            </a>
          </Fragment>
        )
      } else {
        acc.push(<Fragment key={`${index}-text`}>{part}</Fragment>)
      }
      return acc
    }, [])

    return parts
  }

  return (
    <div className='flex'>
      <div className='mr-5'>
        <Avatar userId={user?._id} />
      </div>
      <div>
        <div className='flex items-center'>
          <div>
            {user?.surname} {user?.name} {!user?.isActive && <>(Деактивирован)</>}
          </div>
          <div className='mx-3 inline-block rounded-full border-[2.5px] border-grayscale-200 align-middle' />
          <div className='text-grayscale-200'>{sentCommentTime}</div>
        </div>
        <div className='mt-5 flex items-center gap-7'>
          <div className='rounded-xl px-7 py-6 text-grayscale-150 shadow-xs'>{formatCommentText(comment.body)}</div>
        </div>
      </div>
    </div>
  )
}

export default CommentsItem
